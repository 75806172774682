import React, { Fragment, useEffect, useState } from 'react'
import { PageProps } from 'gatsby'
import parse from 'html-react-parser'
import {
  Head,
  WelcomeHero,
  Accordion,
  Button,
  Modal,
  Form,
  Footer,
} from '~/components'
import { Context as EnterprisesContext } from '~/pages/_empreendimentos'
import { useApi } from '~/siteApi'
//import { Link } from 'gatsby'
import cn from 'classnames'
import * as st from '~/assets/styl/Portal.module.styl'
import * as st1 from '~/assets/styl/Form.module.styl'
import Banner from '~/assets/img/Hero-Portal-do-cliente.jpg'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const windowGlobal: any = typeof window !== 'undefined' ? window : {}

type Context = {
  faqs: Array<{
    name: string
    style: string
    items: Array<{
      name: string
      description: string
      url: string
      external: boolean
      button: string
    }>
  }>
}

const Portal = ({ location, pageContext }: PageProps) => {
  const { faqs }: Context = useApi(pageContext, 'page-portal')
  const _enterprises: EnterprisesContext = useApi(
    { enterprises: [], launchs: [], portfolios: [] },
    'page-enterprise'
  )
  const enterprises = (
    _enterprises.enterprises
      .concat(_enterprises.launchs)
      .concat(_enterprises.portfolios) as Array<{ name: string }>
  ).sort((a, b) => (a.name > b.name ? 1 : -1))
  const [modal, setModal] = useState<string>()

  location.pathname.includes('2-via-boleto') &&
    useEffect(() => {
      if (!enterprises.length) return
      windowGlobal.ViaBoleto = () => setModal('Solicitar 2ª via de boleto')
      setModal('Solicitar 2ª via de boleto')
      return () => (windowGlobal.ViaBoleto = undefined)
    }, [enterprises.length])

  return (
    <>
      <Head location={location} title="Portal do cliente - Alínea Urbanismo" />

      <WelcomeHero title="Portal do cliente" banner={Banner} />

      <section className={st.core}>
        <div className="container">
          <h3>
            Tire suas dúvidas de
            <br />
            forma simples e rápida
          </h3>
          <p>
            Selecionamos algumas das perguntas mais frequentes, com o objetivo
            de ajudar você a sanar suas dúvidas o mais rápido possível.
            <br />A Alínea/J.A Urbanismo agradece o seu interesse e espera que
            as informações contidas aqui lhe sejam muito úteis.
          </p>

          {faqs.map(({ name, style, items }, key) => (
            <Accordion
              header={
                <div className={cn(st.header, style.replace('icon-', 'i-'))}>
                  {name}
                </div>
              }
              className={st.accordion}
              key={key}
            >
              <div className={st.body}>
                {items.map(
                  ({ name, description, button, url, external }, key) => (
                    <Accordion
                      header={
                        <div className={st.header1}>
                          <span className="icon-accordion-arrow"></span>
                          {name}
                        </div>
                      }
                      className={st.accordion1}
                      key={key}
                      open={key === 0}
                    >
                      <div className={st.body1}>
                        <div>
                          {parse(description, {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            replace: (node: any) => {
                              if (
                                node.name === 'div' &&
                                node.attribs.class === 'accordion'
                              ) {
                                const [header, ...body] = node.children
                                return (
                                  <Accordion
                                    header={
                                      <div className={st.header2}>
                                        <span>{header.data}</span>
                                      </div>
                                    }
                                    className={st.accordion2}
                                  >
                                    <div className={st.body2}>
                                      <p>
                                        {body
                                          .filter((i) => i.type === 'text')
                                          .map(({ data }, key) => (
                                            <Fragment key={key}>
                                              {data}
                                              <br />
                                            </Fragment>
                                          ))}
                                      </p>
                                    </div>
                                  </Accordion>
                                )
                              }
                            },
                          })}
                        </div>
                        {button && (
                          <Button
                            type="noArrow tiny"
                            to={url}
                            external={external}
                            onClick={() => !url && setModal(button)}
                          >
                            <div>{button}</div>
                          </Button>
                        )}
                      </div>
                    </Accordion>
                  )
                )}
              </div>
            </Accordion>
          ))}
        </div>
      </section>

      {/* Modais: */}
      <Modal
        open={Boolean(modal === 'Solicitar 2ª via de boleto')}
        onClose={() => {
          setModal(null)
          history.pushState({}, null, '/portal-do-cliente/')
        }}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="boleto-"
          className={st1.form}
          hiddenInputs={{
            category: 'Pagamento',
            portal: 'Não recebi o boleto deste mês, o que faço?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'salary',
              label: 'Vencimento do boleto',
              type: 'select',
              placeholder: 'Selecione',
              options: [
                { name: 'Mês atual' },
                { name: 'Todos os meses em aberto' },
              ],
              className: st1.wide,
            },
            {
              name: 'message',
              label: 'Mensagem',
              type: 'textarea',
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Solicitar alteração de endereço de cadastro')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="address-"
          className={st1.form}
          hiddenInputs={{
            category: 'Pagamento',
            portal: 'Preciso alterar meu endereço de cadastro?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'address',
              label: 'Endereço',
              className: st1.medium,
            },
            {
              name: 'city',
              label: 'Cidade',
              className: st1.medium,
            },
            {
              name: 'state',
              label: 'Estado',
              className: st1.medium,
            },
            {
              name: 'zipCode',
              label: 'CEP',
              className: st1.medium,
            },
            {
              name: 'file',
              label: 'Comprovante de residência',
              type: 'file',
              placeholder:
                'Arraste ou clique para anexar (Formatos aceitos: PDF ou DOC)',
              file: {
                accept: 'application/msword, application/pdf',
                button: (props) => (
                  <Button buttonType="button" type="outlined" {...props}>
                    Procurar
                  </Button>
                ),
              },
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Clique aqui para negociar')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="negotiate-"
          className={st1.form}
          hiddenInputs={{
            category: 'Pagamento',
            portal: 'Tenho parcelas vencidas, como faço para quita-las?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Solicitar cálculo do seu saldo devedor')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="calc-"
          className={st1.form}
          hiddenInputs={{
            category: 'Quitação e/ou Antecipação',
            portal: 'Como faço para saber qual o meu saldo devedor?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Peça o cálculo clicando aqui')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="anticipate-"
          className={st1.form}
          hiddenInputs={{
            category: 'Quitação e/ou Antecipação',
            portal: 'Como faço para quitar antecipadamente minhas parcelas?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'parcelNumber',
              label: 'Nº de parcelas a antecipar',
              mask: 'int',
              className: st1.medium,
            },
            {
              name: 'parcelValue',
              label: 'Valor a antecipar',
              mask: 'currency',
              className: st1.medium,
            },
            {
              name: 'parcelDate',
              label: 'Data prevista para pagamento',
              mask: 'date',
              className: st1.wide,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Solicitar inscrição imobiliária')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="registration-"
          className={st1.form}
          hiddenInputs={{
            category: 'IPTU',
            portal: 'Como verifico débitos de IPTU no meu terreno?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(
          modal === 'Solicite o Termo de Quitação e Autorização para Escritura'
        )}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="term-"
          className={st1.form}
          hiddenInputs={{
            category: 'Transferência e/ou Venda',
            portal:
              'Quitei meu terreno, como faço a transferência para meu nome?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Solicitar cessão de direitos')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="rights-"
          className={st1.form}
          hiddenInputs={{
            category: 'Transferência e/ou Venda',
            portal:
              'Vendi meu terreno para terceiro, como faço a transferência para o nome do novo proprietário?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            {
              name: 'file',
              label: 'Comprovante de residência',
              type: 'file',
              placeholder:
                'Arraste ou clique para anexar (Formatos aceitos: PDF ou DOC)',
              file: {
                accept: 'application/msword, application/pdf',
                button: (props) => (
                  <Button buttonType="button" type="outlined" {...props}>
                    Procurar
                  </Button>
                ),
              },
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Solicitar autorização de construção')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="construction-"
          className={st1.form}
          hiddenInputs={{
            category: 'Solicite sua autorização de construção',
            portal: 'Como faço para solicitar minha autorização de construção?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Solicitar informe de imposto')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="tax-"
          className={st1.form}
          hiddenInputs={{
            category: 'Perguntas Frequentes',
            portal: 'Como faço para obter meu informe de imposto de renda?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'block',
              label: 'Quadra',
              required: false,
              className: st1.medium,
            },
            {
              name: 'part',
              label: 'Lote nº',
              required: false,
              className: st1.medium,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Modal
        open={Boolean(modal === 'Mande-a para nós')}
        onClose={() => setModal(null)}
        className="form-modal"
      >
        <Form
          path="page-portal"
          idPrefix="question-"
          className={st1.form}
          hiddenInputs={{
            category: 'Perguntas Frequentes',
            portal: 'Sua dúvida não está aqui?',
          }}
          inputs={[
            {
              name: 'enterprise',
              label: 'Empreendimento',
              type: 'select',
              placeholder: 'Selecione',
              options: (enterprises || []).map(({ name }) => ({
                name,
              })),
              className: st1.wide,
            },
            {
              name: 'name',
              label: 'Nome do proprietário do lote',
              className: st1.medium,
            },
            {
              name: 'document',
              label: 'CPF',
              mask: 'cpf',
              className: st1.medium,
            },
            {
              name: 'email',
              label: 'E-mail',
              type: 'email',
              className: st1.medium,
            },
            {
              name: 'phone',
              label: 'Telefone',
              mask: 'phone',
              placeholder: '(DD) + Número',
              className: st1.medium,
            },
            {
              name: 'preference',
              label: 'Você prefere que a J.A. Urbanismo entre em contato por',
              options: [{ name: 'E-mail' }, { name: 'Telefone' }],
              className: st1.wide,
            },
            {
              name: 'message',
              label: 'Mensagem',
              type: 'textarea',
              className: st1.wide,
            },
            /*{
              name: 'privacy',
              label: (
                <>
                  Declaro que li e aceito a{' '}
                  <Link to="/politica-de-privacidade/">
                    Política de Privacidade
                  </Link>
                </>
              ),
              type: 'checkbox',
              className: st1.privacy,
            },*/
          ]}
          button={<Button>Enviar</Button>}
          loadingButton={<Button disabled>Enviando...</Button>}
        />
      </Modal>

      <Footer />
    </>
  )
}

export default Portal
